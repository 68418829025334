import React from 'react'
import { rhythm } from '../utils/typography'
import { colors, presets } from '../utils/theme'
import MainHelmet from './MainHelmet'
import withConfig from '../wrappers/withConfig'


export const styles = {
  titleHolder: {
    padding: rhythm(2),
    backgroundColor: colors.brightBlue,
  },
}

const getTitleStyle = (fontSize=3) => ({
  color: colors.white,
  fontSize: rhythm(fontSize),
  [presets.Mobile]: {
    fontSize: rhythm(fontSize),
  },
  fontWeight: 700,
})
class PageTitle extends React.Component {
  render() {
    const { description, defaultDescription, title, defaultTitle, keywords, defaultKeywords } = this.props
    return (
      <div css={styles.titleHolder}>
        <MainHelmet
          description={description || defaultDescription}
          title={title || defaultTitle}
          keywords={keywords || defaultKeywords}
        />
        <h1 css={getTitleStyle(this.props.fontSize)}>{this.props.title}</h1>
        <div css={{ width: '80%', maxWidth: '400px', borderBottom: `4px solid ${colors.gold}`}}/>
      </div>
    )
  }
}

export default withConfig(PageTitle)
