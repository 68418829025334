export const industries = [
  "Commercial",
  "Retail",
  "Warehouse",
  "Manufacturing",
  "Healthcare",
  "Educational",
  "Hospitality",
  "Events",
  "Food Service",
]
